<div class="card card-info">
    <div class="card-header">
        <h5>Paga sicuro con Stripe&copy;</h5>
    </div>
    <div class="card-body">
        <app-stripe [class]="'form-control'" [showPayButton]="false" [amount]="amount" (onsuccess)="confirmPayment($event)" (onerror)="errorCard($event)" (oninvalid)="invalidPayment($event)" (paymentMethodId)="createPaymentMethod($event)"></app-stripe>
    </div>
    <div class="card-footer text-center">
        <button *ngIf="btn_pay" [disabled]="onClickPay" class="btn btn-info" (click)="onClickPay=true;pay();">Paga ora</button>
        
        <img src="assets/safe_checkout.png" style="max-height:64px" />
    </div>
    

</div>