import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';
import {Observable, of} from 'rxjs';
import {Ticket} from "../ticket";



export interface resultPay{
  status:boolean;
  message:string;
  url:string;
}


@Injectable({
  providedIn: 'root'
})
export class TicketsService extends WSService<Ticket> {

  constructor() {super("tickets") }

  getTickets(id_address:number):Observable<Ticket[]>{

    var arg=["t.id_address="+id_address.toString()+" AND t.status=1"];
      
    return  Globals.ws.requestUrl<Ticket[]>("tickets","getItems",arg,true,0,100000);
    
  }


  pay(id_ticket:number):Observable<resultPay>{
    return  Globals.ws.requestUrl<resultPay>("tickets","pay",[id_ticket.toString()]);
  }


  public book(id_ticket:number[]):Observable<[]>{
    return Globals.ws.requestUrl<[]>("tickets","book",[id_ticket.toString()]);
  }

  public bookAll(id:number,id_company="1"):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("tickets","bookAll",[id.toString(),id_company]);
  }


  public confirm(id_ticket:number[]):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("tickets","confirm",[id_ticket.toString()]);
  }

  public confirmAll(id:number,id_company="1"):Observable<Object[]>{
    return Globals.ws.requestUrl<Object[]>("tickets","confirmAll",[id.toString(),"",id_company]);
  }


  public getPDF(id_ticket:number):Observable<string>{
    return Globals.ws.requestUrl<string>("tickets","getPDF",[id_ticket.toString()]);
  }

  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]):Observable<any>{
    return Globals.ws.requestUrl("tickets","getTotals",this.getSQLWhere(filters,ordering,advancedFilter));
  }


  startStripePayment(item:any,payment_method_id,oncomplete){
    Globals.ws.send("tickets","startStripePayment",item,[payment_method_id],oncomplete);
 }

 StripeConfirmPayment(id_ticket,payment_intent_id):Observable<any>{
  return Globals.ws.requestUrl<any[]>("tickets","StripeConfirmPayment",[id_ticket,payment_intent_id]);
}
registerNewUser(address,oncomplete){
  return Globals.ws.send("tickets","registerNewUser",address,[],(res)=>{
    if(oncomplete)
      oncomplete(res);
  });
}
  
 
public getCompanies():Observable<any>{
  return Globals.ws.requestUrl<string>("tickets","getCompanies",[]);
}

public getWSList():Observable<any>{
  return Globals.ws.requestUrl<string>("tickets","getWSList",[]);
}


}
