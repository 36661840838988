import { Component, Input, OnInit, Output, ViewChild ,EventEmitter} from '@angular/core';
import { StripeComponent } from 'projects/core/src/common/stripe/stripe.component';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';
import { TicketsService } from 'projects/modules/src/app/bustickets/services/tickets.service';
import { Ticket } from 'projects/modules/src/app/bustickets/ticket';

@Component({
  selector: 'app-paymentmethod',
  templateUrl: './paymentmethod.component.html',
  styleUrls: ['./paymentmethod.component.css']
})
export class PaymentmethodComponent implements OnInit {
  @ViewChild(StripeComponent)
  stripeComponent:StripeComponent;

  @Input()
  amount:number;

  @Input()
  ticket:Ticket;

  @Input()
  btn_pay:boolean=false;

  @Output()
  onPaymentComplete:EventEmitter<boolean>=new EventEmitter<boolean>();

  @Output()
  onWarning:EventEmitter<string>=new EventEmitter<string>();

  constructor(
    private ticketsService:TicketsService,

  ) { }

  ngOnInit(): void {
  }

  /**** STRIPE */
 payment_method_id="";

 
 
 confirmPayment(transaction:any){
  console.log(transaction);
}

errorCard(error:any){
  alert(error);
  Globals.setLoading(false);
}

invalidPayment(invalid:any){
  Globals.message.showToaster("Dati invalidi per il pagamento. Ricontrollare",ToastMode.WARNING);
}

createPaymentMethod(payment_method_id){
  this.payment_method_id=payment_method_id;
  this.confirmPaymentTicket();
}

paymentConfirmed(booking){
  Globals.loading=false;
  this.onPaymentComplete.emit(true);
}

confirmPaymentTicket(event=null){
  console.log(event);

  Globals.setLoading(true);
  this.ticket.method_payment="stripe";
  this.ticketsService.startStripePayment(this.ticket,this.payment_method_id,(result)=>{
    if(!result){
      Globals.setLoading(false);
      return;

    }
    
    if(result['error']){
        if(result['code']==100){
          this.stripeComponent.stripeService.handleCardAction(
            result['payment_intent_client_secret']
          ).subscribe((result2:any)=>{
            if(result2.error){
              Globals.setLoading(false);
              alert("Errore nella fase di acquisto. Riprovare");
            }else{
              
              this.ticketsService.StripeConfirmPayment(result['id_booking'],result2.paymentIntent.id).subscribe(
                (result3)=>{
                  this.StripeResponseServer(result3,result['id_booking']);
                },(error)=>{
                  alert("Errore nella fase di acquisto. Riprovare");
                  Globals.setLoading(false);
                });
            }
            
            
          },(error)=>{
            Globals.setLoading(false);
            alert("Errore nella fase di acquisto. Riprovare");
          });
        }else{
          Globals.setLoading(false);
          alert("Errore nel pagamento. Controllare che i dati inseriti siano corretti e che vi sia disponibilità nella carta indicata");
          
        }




    }else{
      this.onPaymentComplete.emit(true);
       Globals.loading=false;
      
    }
    
   
  });
}


StripeResponseServer(result:any,id_ticket){
  if(result['warning']){
    this.onWarning.emit(result['warningcode']);
  }

  if(result['success']){
    this.paymentConfirmed(result['ticket']);
    
  }else if(result['requires_action']){

    this.stripeComponent.stripeService.handleCardAction(
      result['payment_intent_client_secret']
    ).subscribe((result2:any)=>{

      this.ticketsService.StripeConfirmPayment(id_ticket,result2.paymentIntent.id).subscribe((result3)=>{
        this.StripeResponseServer(result3,id_ticket);
      },(error)=>{
        alert("Errore nella fase di acquisto. Riprovare");
        Globals.loading=false;
      });
    },(error)=>{
      Globals.loading=false;
    });


    
  }else{
    alert("Errore nella fase di acquisto");
    Globals.loading=false;
   
  }
}

public pay(){
  this.stripeComponent.pay();
}

}
