import { ToastrService } from "ngx-toastr";
import {ToastMode} from "./include/structures";
import { Injectable } from '@angular/core';
import { Globals } from "./globals";

@Injectable()
export class Message{
    
    constructor(private toastr: ToastrService){}
    
    showToaster(message:string,mode:string=ToastMode.INFO){
        Globals.setLoading(false);
        switch(mode){
          case ToastMode.SUCCESS:
            this.toastr.success(message);
            break;
          
          case ToastMode.INFO:
            this.toastr.info(message);
            break;
          
          case ToastMode.DANGER:
            this.toastr.error(message);
            break;
    
          case ToastMode.WARNING:
            this.toastr.warning(message);
            break;

          
        }
    
    }
    

}