import { Output,EventEmitter, Injectable } from '@angular/core';
import { random } from 'mathjs';
import {Paho} from 'ng2-mqtt/mqttws31';
import { Globals } from 'projects/core/src/globals';

@Injectable()
export class C1Connector{

    public connected:boolean=false;
    public autoreconnect:boolean=true;

    @Output()
    onMessageReceived:EventEmitter<any>=new EventEmitter<any>();

    @Output()
    onConnectedSuccefully:EventEmitter<any>=new EventEmitter<any>();

    @Output()
    onDisconnected:EventEmitter<any>=new EventEmitter<any>();

    public c1connectorid:string="";
    client;
    constructor(){

    }
   
    public connectMqtt(){
      let clientid=btoa(Math.random().toString()+Date.now.toString());
      this.c1connectorid=Globals.user['shop']?Globals.user['shop'].getParam("c1connectorid"):Globals.config.config['c1connectorid'];
      this.client = new Paho.MQTT.Client(Globals.config.config['c1connector_serverurl'], Globals.config.config['c1connector_serverport'],clientid);
      
        this.onMessage();
        this.onConnectionLost();
        this.client.connect({onSuccess: this.onConnected.bind(this),useSSL:true,userName:Globals.config.config['c1connector_username'],password:Globals.config.config['c1connector_password']});
        
    
    }

    onConnected() {
    
        //console.log("Connected");
        this.client.subscribe(this.c1connectorid);
        this.connected=true;
        this.onConnectedSuccefully.emit();

    }

    sendMessage(message: any) {
        let packet = new Paho.MQTT.Message(JSON.stringify(message));
        packet.destinationName = this.c1connectorid;
        this.client.send(packet);
        
    }

    onMessage() {
        this.client.onMessageArrived = (message: Paho.MQTT.Message) => {
          let msg=JSON.parse(message.payloadString);
          
          this.onMessageReceived.emit(msg);
          
          console.log('Message arrived : ' + message.payloadString);
        };
    }

    onConnectionLost() {
        this.client.onConnectionLost = (responseObject: Object) => {
          this.connected=false;
          this.onDisconnected.emit();
          //  console.log('Connection lost : ' + JSON.stringify(responseObject));
          if(this.autoreconnect){
            this.connectMqtt();
          }

        };
    }

}