
<div class="modal-body">
    <h4>{{title}}</h4>
    <p>{{description}}</p>
    <br/>

    <button [class.d-none]="lblConfirmButton==''" type="button" class="btn btn-block btn-{{confirmButtonType}}" (click)="close('success')">{{lblConfirmButton}}</button>
    <button [class.d-none]="lblCancelButton==''" type="button" class="btn btn-block btn-dark" (click)="close('cancel')">{{lblCancelButton}}</button>

</div>
