import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe, SearchComponent } from './search.component';
import { SearchRoutingModule } from './search-routing.module';
import { FormsModule } from '@angular/forms';
import { PaymentmethodModule } from '../../modules/paymentmethod/paymentmethod.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-2';


@NgModule({
  declarations: [SafePipe, SearchComponent],
  exports:[SafePipe, SearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    CarouselModule,
    PaymentmethodModule,
    SearchRoutingModule
  ]
})
export class SearchModule { 

  public static addressItem:any;

}
