import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressesService } from 'projects/c1-backend/src/app/services/addresses.service';
import { Globals } from 'projects/core/src/globals';
import { TicketsService } from 'projects/modules/src/app/bustickets/services/tickets.service';
import { Ticket, TicketDetail } from 'projects/modules/src/app/bustickets/ticket';
import { SearchModule } from './modules/search/search.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  get loading():boolean{
    return Globals.loading;
  }

  get addressItem(){
    return SearchModule.addressItem;
  }
  onInit:boolean=false;
  title = 'prontoevai';
  build="13";
  widthMenu:string="0";
  listTickets:Ticket[]=[];
  nextTravel:TicketDetail;
  nextTravelTickets:Ticket;
  selectedTicket: Ticket;

  public static app;

  @ViewChild("modalNextTravel")
  modalNextTravel:NgbModal;

  


  constructor(
    private globals:Globals,
    private ticketsService: TicketsService,
    private addressService:AddressesService,
    private modalService: NgbModal,
    
    ){
    AppComponent.app=this;
    Globals.config.loadConfig(()=>{
      Globals.parameters.loadModules(()=>{

      
        let params=Globals.navigation.getQueryString();
        
        let username=localStorage.getItem(Globals.access.prefix+"_username");
        let password=localStorage.getItem(Globals.access.prefix+"_password");

        Globals.navigation.skipLocationChange=true;

        
        this.onInit=true;
        console.log("Prontovai Build "+this.build);

        Globals.access.loginEvent.subscribe((result)=>{
          
          if(result){
            this.addressService.getAddressByUser(Globals.user.id).subscribe((item)=>{
              if(item){
                SearchModule.addressItem=item;
                this.getTickets(true);
              }else{
                Globals.navigation.onNavigate(["/searchticket"]);
              }
            })
          }else{
            SearchModule.addressItem=null;
          }

          Globals.navigation.onNavigate(["/searchticket"]);
        })

        Globals.access.onLogin(username,password,"1","/searchticket",true,false,true,false);
        Globals.navigation.onNavigate(["/searchticket"]);
      });

    });
  }

  ngOnInit(): void {

    // cookies consent
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#1F3646"
        },
        button: {
          background: "#C9213E",
          text: "#FFFFFF"
        }
      },
      theme: "classic",
      content: {
        message: 'Questo sito utilizza cookie, anche di terze parti, per inviarti pubblicità e servizi in linea con le tue preferenze. Chiudendo questo banner, scorrendo questa pagina o cliccando qualunque suo elemento acconsenti all’uso dei cookie. Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie clicca',
        dismiss: '<i class="fas fa-check"></i> Ok',
        link: 'qui.',
        href: 'https://cookiesandyou.com'
      }
    });

  }

  onMenuClick():void{
    if(this.widthMenu=="0")
      this.widthMenu="100%";
    else
      this.widthMenu="0";
  }

  getTickets(showNextTravel=true):void{
    
    let today:Date=new Date();
    this.listTickets=[];
    if(Globals.isLogin){
      this.ticketsService.getTickets(SearchModule.addressItem.id).subscribe((tickets)=>{

          for(var i=0;i<tickets.length;i++){
            var t=tickets[i];
            let next:boolean=false;
            //verifica quali sono i prossimi viaggi
            for(let tt of t.tickets){
              if(tt.id_state==2){ //solo per biglietti pagati
                let d:Date=new Date(tt.date+" "+tt.time_from.toString()+":00");

                if(d>=today){
                  if(this.nextTravel==undefined){
                    this.nextTravel=tt;
                    this.nextTravelTickets=t;
                  
                    
                  }else{
                    let dnt:Date=new Date(this.nextTravel.date+" 00:00:00");
                    if(d<dnt){ //sostituisci la data
                      this.nextTravel=tt;
                      this.nextTravelTickets=t;
                    }
                  }
                }
              }
            }

            
            this.listTickets.push(t);
          }

          if(showNextTravel)
            if(this.nextTravel!=undefined){
              this.modalService.open(this.modalNextTravel);
            }
          
        
      });
    }
  }


  openTicket(ticket:Ticket){
    this.selectedTicket=ticket;
    for(let t of this.selectedTicket.tickets){
      if(t.listpassengers!="")
        t.passengersArray=JSON.parse(t.listpassengers);
    }

   Globals.navigation.onNavigate(['/ticket']);
    
}

openTicketNextTravel(){
  this.openTicket(this.nextTravelTickets);
  this.modalService.dismissAll('success');
}

closeModalNextTravel(){
  this.modalService.dismissAll('success');
}
}
