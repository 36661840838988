import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import {Observable, of} from 'rxjs';
import {Port} from '../port';



@Injectable({
  providedIn: 'root'
})
export class PortService extends WSService<Port> {


  constructor() {super("ports") }

  getPorts(portFrom:string="",id_company="1"):Observable<Port[]>{
    return Globals.ws.requestUrl<Port[]>("tickets","getPorts",[portFrom,id_company]);
  }

}
