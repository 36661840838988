import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [];

//routes.push({ path: 'login', loadChildren: () => import('projects/core/src/common/login/login.module').then(m => m['LoginModule']) });

routes.push({ path: '', loadChildren: () => import('projects/prontoevai/src/app/views/pages.module').then(m => m.PagesModule) });
routes.push({ path: 'searchticket', loadChildren: () => import('projects/prontoevai/src/app/modules/search/search.module').then(m => m.SearchModule) });

//!!!!!! NECESSARIO PER GARANTIRE LE CORRETTE DIPENDENZE !!!!!!
/*
routes.push({ path: 'admin-booking', loadChildren: () => import('projects/c1-backend/src/app/views/booking/booking.module').then(m => m.BookingModule) });
routes.push({ path: 'admin-address', loadChildren: () => import('projects/c1-backend/src/app/views/address/address.module').then(m => m.AddressModule) });
routes.push({ path: 'admin-project', loadChildren: () => import('projects/c1-backend/src/app/views/projects/projects.module').then(m => m.ProjectsModule) });
routes.push({ path: 'admin-ads', loadChildren: () => import('projects/modules/src/app/ads/ads.module').then(m => m.AdsModule) });
*/
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
