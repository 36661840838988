import { Injectable } from '@angular/core';
import {Address} from '../db/address';
import {WSService} from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';

@Injectable({
  providedIn: 'root'
})
export class AddressesService extends WSService<Address> {

  constructor() {super("addresses") }

  getUser():Observable<Address[]>{
    return  Globals.ws.requestUrl<Address[]>("addresses","getItems",["a.id_type=2"]);
    
  }

  searchName(name):Observable<Address[]>{
    return  Globals.ws.requestUrl<Address[]>("addresses","getItems",["a.name LIKE '%"+name+"%'"]);
  }

  searchByFiscal(value):Observable<Address[]>{
    return  Globals.ws.requestUrl<Address[]>("addresses","getItems",["a.tax_code = '"+value+"' OR a.vat_number='"+value+"'"]);
  }

  searchByContact(contact):Observable<Address[]>{
    return  Globals.ws.requestUrl<Address[]>("addresses","searchByContact",[contact]);
  }


  getAddressByUser(id_user:number):Observable<Address>{
    return  Globals.ws.requestUrl<Address>("addresses","getAddressByUser",[id_user.toString()]);
    
  }

  verifyTax(search_vat:string,search_tax:string,id:number):Observable<Address[]>{

    let where:string[]=[];
    if(search_vat!="")
      where.push("a.vat_number='"+search_vat+"'");
    
    if(search_tax!="")
      where.push("a.tax_code='"+search_tax+"'");
    


    return  Globals.ws.requestUrl<Address[]>("addresses","getItems",["a.id!="+id.toString()+" AND ("+where.join(" OR ")+")"]);
    
  }


  joinAddresses(ids:string[]):Observable<void>{
    return  Globals.ws.requestUrl<void>("addresses","joinAddresses",[ids.join(",")]);
  }


  searchByVies(vat_number:string):Observable<any>{
    return  Globals.ws.requestUrl<void>("addresses","searchByVies",[vat_number]);
  }

  getCountryFromZip(zip:string):Observable<any>{
    return  Globals.ws.requestUrl<void>("addresses","getCountryFromZip",[zip]);
  }
  
}
