
import {ModulesService} from '../lib/modules.service';
import { Globals } from '../globals';
import { EventEmitter, Injectable, Output } from '@angular/core';

export class Extension{
    id:number;
    modulename:string;
    params:any;
    status:number;
    label:string;
    url:string;
    icon:string;
    permissions:any;
    type:number;
    ordering:number;
    stored:any;
    defaultmodule:boolean;
    showMenu:boolean;
    table:string;
    core:boolean;
    value:any;

  

    public getParam(param,defaultreturn=null){
        if(Array.isArray(this.params))
            for(let p of this.params){
                if(p.name==param){
                    if(p.type=="boolean"){
                        if(typeof(p.value)=="boolean"){
                            return p.value;
                        }else{
                            return p.value=="true"?true:false;
                        }
                        
                    }
                    
                    return p.value;
                }
            }
        return defaultreturn;
    }

    public getStored(storename,defaultreturn=null){
        if(Array.isArray(this.stored))
            for(let t of this.stored){
                if(t.name==storename){
                    return t.value;
                }
            }
        return defaultreturn;
    }


    public addStored(storename,value){
        //verifica se il parametro è stato già impostato
        let p=this.getStored(storename);
        if(p==null){
            p={} as Object;
            p['name']=storename;
            p['value']=value;
            this.stored.push(p);

        }else{

            p['value']=value;
            
        }

        let modulesService:ModulesService=new ModulesService();
        modulesService.save(p,()=>{},false,false);
    }


}

@Injectable()
export class Parameters{

    modules:Extension[];

    @Output()
    loadModulesEvent:EventEmitter<boolean>=new EventEmitter();
   
    createParam(modulename,name,label,type,params=null,defaultValue=null){
        //cerca se il parametro esista
        for(let i=0;i<this.modules.length;i++){

            if(this.modules[i].modulename==modulename){
                for(let j=0;j<this.modules[i].params.length;j++){
                    if(this.modules[i].params[j].name==name){
                        this.modules[i].params[j].label=label;
                        this.modules[i].params[j].type=type;
                        if(params!=null){
                            if(type=="table"){
                                this.modules[i].params[j].fields=params;
                            }else{
                                this.modules[i].params[j].params=params;
                            }

                            
                        }
                        if(defaultValue!=null){
                            if(!this.modules[i].value || this.modules[i].value==null){
                                this.modules[i].value=defaultValue;
                            }
                        }
                            
                        return;
                    }
                }

                //se il parametro non esiste crealo
                let p={};
                p['name']=name;
                p['label']=label;
                
                p['type']=type;
                if(params!=null){
                    if(type=="table"){
                        p['fields']=params;   
                    }else{
                        p['params']=params;
                    }
                }

                if(defaultValue!=null){
                    if(!p['value'] || p['value']==null){
                        p['value']=defaultValue;
                    }
                }

                
                if(!Array.isArray(this.modules[i].params))
                    this.modules[i].params=[];
                this.modules[i].params.push(p);
                
                //salva il parametro
                Globals.ws.send("modules","save",this.modules[i],[],()=>{

                },"");



            }
        }
    }

    loadModules(oncomplete){
        Globals.ws.requestUrl<Extension[]>("modules","getItems",[],true,0,100000).subscribe((items)=>{
            
            this.modules=[];
            
            //parserizza i parametri
            for(let i of items){

                let m:Extension=new Extension();
                m.id=i.id;
                m.label=i.label;
                m.modulename=i.modulename;
                try{
                    m.params=JSON.parse(i.params);
                }catch{
                    m.params=[];
                }

                try{
                    m.stored=JSON.parse(i.stored);
                }catch{
                    m.stored=[];
                }

                try{
                    m.permissions=JSON.parse(i.permissions);
                }catch{
                    m.permissions=[];
                }
                
                m.status=i.status;
                m.type=i.type;
                m.icon=i.icon;
                m.ordering=i.ordering;
                m.url=i.url;
                m.defaultmodule=i.defaultmodule;
                if(i.showMenu!=undefined)
                    m.showMenu=i.showMenu;
                else
                    m.showMenu=true;
                m.table=i.table;
                m.core=i.core;
                this.modules.push(m);


                

            }   

            if(this.loadModulesEvent)
                this.loadModulesEvent.emit(true);

            oncomplete();
        });
    }

    getAuthorizedModules(){
        let modules=[];

        
        for(let m of this.modules){
            if(Globals.user && m.permissions.indexOf(Globals.user.role)>-1 && m.status==1){
                modules.push(m);
              }
        }

        return modules;

    }

    get(modulename){
        if(this.modules)
            for(let m of this.modules){
                if(m.modulename==modulename){
                    if(m.status==1)
                        return m;
                }
            }

        return null;
    }

    verifyAuth(url){
        //dividi l'url e considera il primo segmento
        const segments=url.split("/");
        let u;
        if(segments.length>1){
            u="/"+segments[1];
        }else{
            u="/"+segments[0];
        }

        
      
        if(this.modules!=null){
            for(let m of this.modules){
                if(u==m.url){
                    if(m.permissions.indexOf(Globals.user.role)>-1)
                        return true;
                }
            }
        }

        return false;
    }
    
    getDefaultModule(){

        let modules=this.getAuthorizedModules();
        for(let m of modules){
            if(m.defaultmodule){
                return m;
            }
        }
        if(modules.length>0)
            return modules[0];
        else
            return null;
    }

    isModuleEnabled(modulename){
      
        let module:Extension=Globals.parameters.get(modulename);
  
        if(module){

           
          //verifica se l'utente può visualizzarlo
          if(module.permissions.indexOf(Globals.user.role)>-1){
            return true;
          }
        }
  
        return false;
        
      }

}