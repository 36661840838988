import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import {Observable, of} from 'rxjs';
import {TravelGroup} from "../travelgroup";



@Injectable({
  providedIn: 'root'
})
export class TravelService {

  constructor() { }

  getTravel(portFrom:string,portTo:string,dateStart:Date,dateReturn:Date=null,adults=1,children=0,id_company="1"):Observable<TravelGroup>{


     //correggi la data per poterla calcolare perfettamente
     dateStart.setDate(dateStart.getDate()+1);

     var arg=[portFrom,portTo,dateStart.toISOString().slice(0, 10)];
     if(dateReturn!=null){
       dateReturn.setDate(dateReturn.getDate()+1);
       arg.push(dateReturn.toISOString().slice(0, 10));
     }else{
      arg.push("");
     }
     arg.push(adults.toString());
     arg.push(children.toString());
     
     arg.push(id_company);
     return  Globals.ws.requestUrl<TravelGroup>("tickets","getTravels",arg);
    
     
    
  }
}
