
import { Address } from 'projects/c1-backend/src/app/db/address';
import { CustomField } from 'projects/core/src/include/structures';
import { Port } from './port';

export interface Passenger{
    name:string;
    lastname:string;
    telephone:string;
    seat:string;
    cost:number;
    type:string;
    offer:number;
    booking_number:string;
    ticket_number:number;
}


export class TicketDetail{
    id:number;
    id_ticket:number;
    sid:string;
    date:Date;
    tnx_id:number;
    port_from:string;
    port_to:string;

    time_from:string;
    time_to:string;
    station_from:string;
    station_to:string;
    gps_station_from:string;
    gps_station_to:string;
    id_type:number;
    id_state:number;
    cost:number;
    listpassengers:string;
    passengersArray:Passenger[];
    file:string;
    pnr:string;
    seats:string;

    _port_from:Port;
    _port_to:Port;

    set PortFrom(value){
        this._port_from=value;
        this.port_from=value.stop;
    }

    get PortFrom(){
        return this._port_from;
    }



    constructor(){
        this.passengersArray=[];
    }
    

}

export class Ticket{
    id:number;
    cost:number;
    adults:number;
    gps_port_from:string;
    gps_port_to:string;
    gps_station_from:string;
    gps_station_to:string;
    port_from:string;
    port_to:string;
    children:number;
    blocked:boolean;
    blocked_time:string;
    blocked_by:number;
    sid:string;
    sid_return:string;
    tnx_id:number;
    id_type:number;
    id_state:number;
    customfields:CustomField[];
    id_address:number;
    id_company:number;
    addressItem:Address;
    source:string;
    status:number;
    username:string;
    created_by:number;
    created:Date;
    id_card:number;
    tickets:TicketDetail[];
    financialaccount:string;
    method_payment:string;
    transaction_id:string;
    companyname:string;
    companylogo:string;

    _port_from:Port;
    _port_to:Port;

    set PortFrom(value){
        this._port_from=value;
        this.port_from=value.stop;
        this.gps_port_from=value.lat.toString()+","+value.lon.toString();
    }

    get PortFrom(){
        return this._port_from;
    }

    set PortTo(value){
        this._port_to=value;
        this.port_to=value.stop;
        this.gps_port_to=value.lat.toString()+","+value.lon.toString();
    }

    get PortTo(){
        return this._port_to;
    }


    constructor(){
        this.tickets=[];
        
    }
}