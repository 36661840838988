<!-- Upper Bar -->
<div *ngIf="status>1">
    <nav class="bg-primary fixed-top p-2">
        <button class="btn btn-sm float-left " (click)="goToBack()"><i class="fa fa-2x fa-arrow-left"></i></button>
        <h4 class="text-white float-right p-2 m-0">{{title}}</h4>
    </nav>
</div>

<!-- CERCA VIAGGIO -->
<div *ngIf="status==STATUS_SEARCH_TRAVEL" >
    <section class="banner banner_search" id="top">
            <div class="row">
                                
                <!-- only desktop -->
                <div class="col-lg-5 p-0 d-none d-lg-block">
                    <div class="left-side">
                        <div class="logo">
                            <img src="assets/img/logo.png" alt="Pronto(e)vai">
                        </div>
                        <div class="claim-desktop">
                            <h4>Acquista online <span>o al telefono.</span><br> 
                            Noi ti seguiamo in tutto!</h4>  
                            <a href="tel:+390983018006" ><button class="btn btn-sm float-left mr-3" style="background-color: #075e54;color:white"><h6 class="mx-2"><i class="fa fa-phone-alt mr-2 mt-2"></i>Chiama</h6></button></a>
                            <a href="whatsapp:+393938363509" ><button class="btn btn-sm float-left mr-3" style="background-color: #25D366;color:white"><h6 class="mx-2"><i class="fab fa-whatsapp mr-2 mt-2"></i>Chatta</h6></button></a>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-7" style="padding: 10px 30px">
                    <section>
                        <div class=" d-none d-lg-block p-4"></div>
                            <div class="submit-form max_width">
                                <!--<h4 class="mb-3 d-none d-sm-block">Scegli il tuo prossimo <em>viaggio</em></h4>-->
                                <div class="row">
                                   <!-- andata / ritorno -->
                                    <div class="btn-group btn-group-toggle col-12 mb-2" ngbRadioGroup name="radioBasic" [(ngModel)]="ticket.id_type">
                                        <label ngbButtonLabel class="col-6 btn-radio">
                                           <!--<img class="d-none d-md-block p-2 m-auto" src="assets/img/bus-a_white.png" width="100" >--> 
                                            <input ngbButton type="radio" [value]="ONLY_START"><i class="fas fa-long-arrow-alt-down"></i> Solo Andata
                                        </label>
                                        <label ngbButtonLabel class="col-6 btn-radio ">
                                            <!--<img class=" d-none d-md-block p-2 m-auto" src="assets/img/bus-ar_white.png" width="100" >--> 
                                            <input ngbButton type="radio" [value]="START_AND_RETURN"><i class="fas fa-long-arrow-alt-down"></i><i class="fas fa-long-arrow-alt-up"></i> Andata e Ritorno
                                        </label>
                                    </div>
                                    <!-- Da / A -->
                                    <div class="col-2 pr-0">
                                        <div class="text-center" >
                                            <small *ngIf="ticket.id_type==ONLY_START">A</small>
                                            <small *ngIf="ticket.id_type==START_AND_RETURN">AR</small>
                                            <br>
                                            <i class="fas fa-long-arrow-alt-down fa-2x text-secondary"></i>
                                            <i class="fas fa-long-arrow-alt-up fa-2x text-secondary" *ngIf="ticket.id_type==START_AND_RETURN"></i>
                                        </div>
                                    </div>
                                    <div class="col-2 px-0 text-right" style="line-height: 2;">
                                        <label for="from">Da:&ensp;</label><br>
                                        <label for="to">A:&ensp;</label>
                                        <ngcontainer *ngIf="ticket.id_type==START_AND_RETURN" >
                                            <br><label>e poi A:&ensp;</label>
                                        </ngcontainer>
                                    </div>
                                    <div class="col-8 pl-0">
                                        <div class="col-12 px-0">
                                            <select class="custom-select" [(ngModel)]="ticket.port_from" (ngModelChange)="getPortsTo()" data-live-search="true" data-live-search-placeholder="Da dove vuoi partire?">
                                                <!-- <option value='' *ngFor="let port of ports_from">{{port.stop}}</option>  -->
                                                <option *ngFor="let port of ports_from">{{port.stop}}</option> 
                                            </select>
                                        </div>
                                        <div class="col-12 px-0">
                                            <select class="custom-select" [(ngModel)]="ticket.port_to" placeholder="Dove vuoi arrivare?">
                                                <option *ngFor="let port of ports_to" >{{port.stop}}</option> 
                                            </select>
                                        </div>
                                        <div class="col-12 px-0" *ngIf="ticket.id_type==START_AND_RETURN">
                                            <div style="padding: 4px 10px;font-weight:bold">
                                                {{ticket.port_from}} 
                                            </div>
                                        </div>
                                    </div>
                                   <hr>
                                    <!-- adulti e bambini -->
                                    <div class="col-6">
                                        <label for="to">Adulti:</label>
                                        <div class="input-group input-group-sm" >
                                            <i class="fas fa-male fa-2x mr-2 text-secondary"></i>
                                            <div class="input-group-prepend ">
                                                <button class="btn btn-primary" (click)="reduceQuantity('adults',1);"><i class="fa fa-minus"></i></button>
                                            </div>
                                            <input readonly class="form-control px-0 m-0 text-center font-weight-bold"  [(ngModel)]="ticket.adults"  />
                                            <div class="input-group-prepend ">
                                                <button class="btn btn-primary" (click)="addQuantity('adults');"><i class="fa fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label for="to">Bambini <small>(max 12 anni)</small>:</label>
                                        <div class="input-group input-group-sm">
                                            <i class="fas fa-child mt-2 mr-2 text-secondary" style="font-size: 1.3rem;"></i>
                                            <div class="input-group-prepend ">
                                                <button class="btn btn-primary" (click)="reduceQuantity('children');"><i class="fa fa-minus"></i></button>
                                            </div>
                                            <input readonly class="form-control px-0 m-0 text-center font-weight-bold" [(ngModel)]="ticket.children"  />
                                            <div class="input-group-prepend ">
                                                <button class="btn btn-primary" (click)="addQuantity('children');"><i class="fa fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- giorno di partenza e arrivo -->
                                    <div class="long_version col-12 mt-3" >
                                        <div class="" *ngIf="ticket.id_type==ONLY_START">
                                            <i class="fas fa-calendar-day fa-2x mr-2 text-secondary"></i>
                                            <label for="date_start_selected">Data della partenza:</label><br/>
                                            <ngb-datepicker #d  [(ngModel)]="date_start_selected" locale="it" [displayMonths]="1" outsideDays="visible"></ngb-datepicker>
                                        </div>
                                        <div class="" *ngIf="ticket.id_type==START_AND_RETURN">
                                            <i class="fas fa-calendar-alt fa-2x mr-2 text-secondary"></i>
                                            <label for="date_start_selected">Date di andata e ritorno:</label><br/>
                                            <ngb-datepicker #d  (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="visible"></ngb-datepicker>
                                            <ng-template #t let-date let-focused="focused">
                                                <span class="custom-day"
                                                        [class.focused]="focused"
                                                        [class.range]="isRange(date)"
                                                        [class.faded]="isHovered(date) || isInside(date)"
                                                        (mouseenter)="hoveredDate = date"
                                                        (mouseleave)="hoveredDate = null">
                                                    {{ date.day }}
                                                </span>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="short_version col-12 my-3">
                                        <div class="row">
                                            <div class="col-12 ">
                                                <label for="date_start_selected" *ngIf="ticket.id_type==ONLY_START">Data della partenza:</label>
                                                <label for="date_start_selected" *ngIf="ticket.id_type==START_AND_RETURN">Date di andata e ritorno:</label>
                                                <div class="input-group input-group-sm">
                                                    <i class="fas fa-calendar-day fa-2x text-secondary" *ngIf="ticket.id_type==ONLY_START"></i>
                                                    <i class="fas fa-calendar-alt fa-2x text-secondary" *ngIf="ticket.id_type==START_AND_RETURN"></i>
                                                    <span class="form-control ml-2 text-center font-weight-bold bg-light" (click)="openModalDayTravel()">
                                                        {{date_start_selected.day}}/{{date_start_selected.month}}/{{date_start_selected.year}}
                                                        <span *ngIf="ticket.id_type==START_AND_RETURN"> - {{date_return_selected.day}}/{{date_return_selected.month}}/{{date_return_selected.year}}</span>
                                                    </span>
                                                    <div class="input-group-prepend ">
                                                        <button class="btn btn-primary" (click)="openModalDayTravel()">Scegli</button>
                                                    </div>
                                                   
                                                </div>

                                            </div>
                                            <div class="col-2 text-right">
                                               
                                            </div>
                                            <div class="col-10">                                           
                                               
                                            </div>
                                        </div> 
                                    </div> 
                                    <!-- pulsante Cerca -->
                                    <div class="col-12 mt-2 text-center">
                                        <button [disabled]="(ticket.port_from=='' || ticket.port_from==undefined || ticket.port_to==undefined || ticket.port_to=='')" (click)="goTo(STATUS_SELECT_RACE_START)" 
                                        class="btn btn-lg btn-primary btn-block heartbeat"><i class="fas fa-search mr-2 "></i> Cerca viaggio</button>
                                    </div>
                                    
                                </div>
                            </div>
                    </section>
                </div>
            </div>
    </section>
</div>


<!-- SELEZIONA CORSA DI ANDATA -->
<div *ngIf="status==STATUS_SELECT_RACE_START" >
    <div class="container mt-3">
        <div class="row">
            <div class="d-none d-lg-block p-3"></div>
            <div class="col-12 ">
                <div class="text-center" *ngIf="travelsstart.length==0">
                    <img class="borded" src="assets/img/search_travel.gif" width="300">
                    <h3 class="mt-2">Attendi.<br>
                        Stiamo cercando tutte le corse per la tratta<br>
                    <b>{{ticket.port_from}}</b> - <b>{{ticket.port_to}}</b><br/>
                  
                        
                </h3>
                </div>
                <div class="long_version text-center p-1">
                    <label>Totale posti richiesti: <b>{{ticket.adults+ticket.children}}</b></label>
                </div>
                
                <owl-carousel-o [options]="travelsOptions" *ngIf="travelsstart.length>0">
                    
                    <ng-container *ngFor="let traveldate of travelsstart">
                        <ng-template carouselSlide [id]="traveldate.id">
                            <div class="card" >
                                <div class="col-12 card-header">
                                    <h4 class="card-title text-primary"><i class="fas fa-calendar-day"></i><b> {{traveldate.date | date:'EEEE dd'}}</b></h4>
                                    <span class="text-sm">{{traveldate.date | date:'LLLL yyyy'}}</span>
                                </div>
                               <div class="card-body">
                                    <div class="row text-center " *ngFor="let travel of traveldate.travel">
                                        <div class="col-12">
                                            <h3 class="text-secondary" *ngIf="ticket.id_type==START_AND_RETURN" ><b>ANDATA</b></h3>
                                            Partenza da {{ticket.port_from}} alle ore <b>{{travel.START_TIME}}</b><br/>
                                            Arrivo a {{ticket.port_to}} alle ore <b>{{travel.STOP_TIME}}</b><br/><br/>
                                            <small>Costo totale</small>
                                            <h5 class="text-primary"><b>{{travel.cost | currency:'EUR'}}</b></h5>                                        
                                            <button class="btn btn-lg btn-primary heartbeat mt-2" *ngIf="travel.COUNT_SEATS >= (ticket.adults+ticket.children)" (click)="onSelectTravel(travel)">Seleziona <i class="fas fa-chevron-right ml-2"></i></button>
                                            <br>
                                            <label class="text-danger" *ngIf="travel.COUNT_SEATS>0 && travel.COUNT_SEATS < (ticket.adults+ticket.children)">
                                                Ci dispiace! Ci sono solo <b>{{travel.COUNT_SEATS}}</b> posti disponibili.
                                            </label>
                                            <label class="text-danger" *ngIf="travel.COUNT_SEATS==0">
                                                Ci dispiace! Non ci sono più posti disponibili.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>


<!-- SELEZIONA CORSA DI RITORNO -->
<div *ngIf="status==STATUS_SELECT_RACE_RETURN">
    <div class="container mt-3">
        <div class="row">
            <div class="d-none d-lg-block p-3"></div>
            <div class="col-12">
                <div class="text-center" *ngIf="travelsreturn.length==0">
                   <img class="borded" src="assets/img/search_travel_return.gif" width="300">
                    <h3 class="mt-2">Attendi.<br>
                        Stiamo cercando tutte le corse per la tratta di ritorno<br>
                    <b>{{ticket.port_to}}</b> - <b>{{ticket.port_from}}</b></h3>
                </div>
                <div class="long_version text-center p-1">
                    <label>Totale posti richiesti: <b>{{ticket.adults+ticket.children}}</b></label>
                </div>
                <owl-carousel-o [options]="travelsOptions" *ngIf="travelsreturn.length>0">
                    <ng-container *ngFor="let traveldate of travelsreturn">
                        <ng-template carouselSlide [id]="traveldate.id">
                            <div class="card ">
                                <div class="col-12 card-header">
                                    <h4 class="card-title text-primary"><i class="fas fa-calendar-alt"></i><b> {{traveldate.date | date:'EEEE dd'}}</b></h4>
                                    <span class="text-sm">{{traveldate.date | date:'LLLL yyyy'}}</span>
                                </div>
                               <div class="card-body">
                                <div class="row text-center" *ngFor="let travel of traveldate.travel">
                                    <div class="col-12">
                                        <h4 class="text-secondary"><b>RITORNO</b></h4>
                                        Partenza da {{ticket.port_to}} alle ore <b>{{travel.START_TIME}}</b><br/>
                                        Arrivo a {{ticket.port_from}} alle ore <b>{{travel.STOP_TIME}}</b><br/><br/>
                                        <small>Costo totale</small>
                                        <h5 class="text-primary"><b>{{travel.cost | currency:'EUR'}}</b></h5>                                    
                                        <button class="btn btn-lg btn-primary heartbeat mt-2" [disabled]="travel.COUNT_SEATS < (ticket.adults+ticket.children)" (click)="onSelectTravel(travel,'Ritorno')">Seleziona <i class="fas fa-chevron-right ml-2"></i></button>
                                        <br>
                                        <label class="text-danger" *ngIf="travel.COUNT_SEATS>0 && travel.COUNT_SEATS < (ticket.adults+ticket.children)">
                                                Ci dispiace! Ci sono solo {{travel.COUNT_SEATS}} posti disponibili.
                                        </label>
                                        <label class="text-danger" *ngIf="travel.COUNT_SEATS==0">
                                                Ci dispiace! Non ci sono più posti disponibili.
                                        </label>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>


<!-- SELEZIONA POSTI -->
<div *ngIf="status==STATUS_SELECT_SEATS">
    <div class="container">
        <div class="row mt-5">
            <div class=" d-none d-lg-block p-5"></div>
            <div class="col-12">
                <owl-carousel-o [options]="passengersOptions" id="passengers">
                    <ng-container *ngFor="let passenger of passengers;let i=index">
                        <ng-template carouselSlide [id]="passenger.id">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">
                                        <span class="fa-stack" *ngIf="passenger.type=='ADULT'" style="vertical-align: middle;">
                                            <i class=" text-primary fas fa-circle fa-stack-2x"></i>
                                            <i class="fas fa-male fa-stack-1x fa-inverse"></i>
                                        </span>
                                        <span class="fa-stack" *ngIf="passenger.type=='CHILDREN'" style="vertical-align: middle;">
                                            <i class=" text-primary fas fa-circle fa-stack-2x"></i>
                                            <i class="fas fa-child fa-stack-1x fa-inverse"></i>
                                        </span>
                                         {{passenger.id}}° passeggero 
                                         <b>
                                            <span *ngIf="passenger.type=='ADULT'">adulto</span>
                                            <span *ngIf="passenger.type=='CHILDREN'">bambino</span>
                                        </b>
                                    </h4>
                                </div>
                                <div class="card-body">
                                    <label for="to">Nome:</label>
                                    <input type="text" class="form-control"  name="passengername{{i}}" [(ngModel)]="passenger.name" />
                                 
                                    <label for="to">Cognome:</label>
                                    <input type="text" class="form-control" name="passengerlastname{{i}}" [(ngModel)]="passenger.lastname" />
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <div class="col-12 mt-1 text-center">
                <button class="btn btn-sm " (click)="openModalInfoSeat()"><i class="fas fa-info-circle"></i><small> Perchè non posso selezionare il numero del posto a sedere?</small></button>
            </div>
        </div>
        <div class="text-center mt-2 p-2">
            <h5 class="mb-2">Totale da pagare <span class="text-primary text-left"><strong>{{ticket.cost | currency:'EUR'}}</strong></span></h5>
        </div>
        <div class="text-center p-2">
            <button class="btn btn-lg btn-primary heartbeat" (click)="confirmPassengers();">Conferma i passeggeri<i class="fas fa-chevron-right ml-2"></i></button>
        </div>
    </div>
</div>


<!-- NOMITATIVO PASSEGGERI -->
<!--
<div *ngIf="status==STATUS_INSERT_PASSENGERS">
    <div class="container mt-3">
        <div class="text-center fixed-bottom  p-2">
            <button class="btn btn-sm btn-primary" (click)="confirmPassengers();">Conferma i passeggeri <i class="fas fa-chevron-right"></i></button>
        </div>
    </div>
</div>
-->


<!-- RIEPILOGO BIGLIETTI -->
<div *ngIf="status==STATUS_CONFIRM_TICKET">
    <div class="container text-center">
        <div class=" d-none d-lg-block p-5"></div>
        <div class="card mt-2" >
            <div class="card-header">
                <h4>{{ticket.port_from}} - {{ticket.port_to}}</h4>
                <span class="text-sm">{{travel_selected_start.TRIP_DATE | date:'EEEE dd LLLL yyyy'}}</span>
            </div>
            <div class="card-body">
                <div class="row text-center">
                    <div class="col-5">
                        <h3><strong>{{travel_selected_start.START_TIME}}</strong></h3>
                    </div>
                    <div class="col-2 p-0 mt-2">
                        <img *ngIf="true" src="assets/img/bus-a.png" style="width:100%;">
                    </div>
                    <div class="col-5">
                        <h3><strong>{{travel_selected_start.STOP_TIME}}</strong></h3>
                    </div>
                </div>
                <div class="row text-center mb-2">
                    <div class="col-6">
                        <a target="_blank" href="https://www.google.it/maps/place/{{travel_selected_start.START_ADDRESS}}, {{ticket.port_from}}" class="text-uppercase">
                            <i class="fas fa-map-marker-alt mr-2"></i><small>{{travel_selected_start.START_ADDRESS}}</small></a>
                    </div>
                   
                    <div class="col-6">
                        <a target="_blank" href="https://www.google.it/maps/place/{{travel_selected_start.STOP_ADDRESS}}, {{ticket.port_to}}" class="text-uppercase">
                            <i class="fas fa-map-marker-alt mr-2"></i> <small>{{travel_selected_start.STOP_ADDRESS}}</small></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-2" *ngIf="ticket.id_type==START_AND_RETURN">
            <div class="card-header">
                <h4>{{ticket.port_to}} - {{ticket.port_from}}</h4>
                <span class="text-sm">{{travel_selected_return.TRIP_DATE | date:'EEEE dd LLLL yyyy'}}</span>
            </div>
            <div class="card-body">
                <div class="row text-center">
                    <div class="col-5">
                        <h3><strong>{{travel_selected_return.START_TIME}}</strong></h3>
                    </div>
                    <div class="col-2 p-0 mb-2">
                        <img *ngIf="true" src="assets/img/bus-r.png" style="width:100%;">
                    </div>
                    <div class="col-5">
                        <h3><strong>{{travel_selected_return.STOP_TIME}}</strong></h3>
                    </div>
                </div>
                <div class="row text-center mb-2">
                    <div class="col-6">
                        <a target="_blank" href="https://www.google.it/maps/place/{{travel_selected_start.STOP_ADDRESS}}, {{ticket.port_to}}" class="text-uppercase">
                            <i class="fas fa-map-marker-alt mr-2"></i><small>{{travel_selected_start.STOP_ADDRESS}}</small></a>
                    </div>
                    <div class="col-6">
                        <a target="_blank" href="https://www.google.it/maps/place/{{travel_selected_start.START_ADDRESS}}, {{ticket.port_from}}" class="text-uppercase">
                            <i class="fas fa-map-marker-alt mr-2"></i><small>{{travel_selected_start.START_ADDRESS}}</small></a>
                    </div>
                </div>
            </div>
        </div>
       <!--<div class="card">
            <div class="card-header">
                <h4 class="card-title">Passeggeri</h4>
            </div>
            <div class="card-body" style="overflow-y: scroll;height:150px" >
                <div class="row" *ngFor="let passenger of passengers">    
                    <div class="col-12">
                        <p class="text-uppercase"><strong>{{passenger.name}} {{passenger.lastname}}</strong></p>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="text-center mt-2">
            <h5 class="mb-2">Totale da pagare <span class="text-primary text-left"><strong>{{ticket.cost | currency:'EUR'}}</strong></span></h5>
        </div>
        <div class="text-center p-2 mx-2">
            <button class="btn btn-lg btn-primary heartbeat" (click)="goTo(STATUS_PAY);">Prosegui<i class="fas fa-chevron-right ml-2"></i></button>
        </div>
    </div>
</div>


<!-- DATI INTESTATARIO -->
<div *ngIf="status==STATUS_PAY">
    <div class=" d-none d-lg-block p-3"></div>
    <div class="container mt-2">
        <div class="text-center" *ngIf="onSaving">
            <img class="borded" src="assets/img/search_travel.gif" width="300">
            <h3 class="mt-2">Attendi.<br>
                Sto salvando la tua prenotazione<br>
            </h3>
        </div>
        <div class="card" *ngIf="!onSaving">
           <div class="card-body text-center">
                <div class="row">
                    <div class="col-md-12">
                            <label><i class="fa fa-phone-alt"></i> Telefono per ricevere il biglietto via SMS:</label>
                            <input type="tel" class="form-control text-center" style="font-size:22pt;font-weight: bolder;" [(ngModel)]="client['telephone']" />                                        <div class="input-group-prepend ">
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-12 mt-2">
                        <label for="to"><i class="fa fa-user"></i> Cognome e nome:</label>
                        <input type="text" class="form-control text-center" [(ngModel)]="client['name']" />
                    </div>
                    
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <label for="to"><i class="fa fa-envelope"></i> Indirizzo email su cui ricevere il biglietto (facoltativo):</label>
                        <input type="email" class="form-control text-center" [(ngModel)]="client['email']" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-1">
                        <input type="checkbox"  checked />
                    </div>
                    <div class="col-11 text-left">
                        <label>Accetto le condizioni di vendita e la <a (click)="openCondition()"><u>privacy policy</u></a></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center p-2">
            <button class="btn btn-lg btn-primary heartbeat" (click)="confirmTicket();">Registrati e Paga<i class="fas fa-chevron-right ml-2"></i></button>
        </div>
    </div>
</div>

<!-- ORDINE CONFERMATO -->
<div *ngIf="status==ORDER_CONFIRMED">
    <div class="container mt-5">
        <h2>I tuoi biglietti sono in stato di attesa</h2>
        <p>Per confermare i tuoi biglietti effettua il pagamento utilizzando il form sottostante. Inserisci i dati della tua carta di credito/debito e premi su "Paga ora"</p>

        <app-paymentmethod [ticket]="ticket" [amount]="ticket.cost" (onPaymentComplete)="paymentComplete($event)" (onWarning)="warning($event)"></app-paymentmethod>

        <div class="text-center  p-2">
            <button class="btn btn-sm btn-primary heartbeat" (click)="pay();">Paga ora {{ticket.cost | currency:" &euro;"}}<i class="fas fa-chevron-right ml-2"></i></button>
        </div>
    </div>
</div>


<!-- ORDINE CONCLUSO -->
<div *ngIf="status==ORDER_COMPLETE">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center mt-5">
                <h5 class="mt-3 mb-0"><span class="text-success text-center"><i class="fa fa-lg fa-check fa-2x mr-2"></i></span><b>Grazie per aver acquistato i tuoi biglietti su Prontoevai.</b></h5>
                <p>A breve riceverai i tuoi biglietti via SMS e sull'indirizzo email che hai indicato.</p>
            </div>
            <div class="row px-3 ">
                <div class="col-4 mt-4">
                    <img src="assets/img/your_tickets.jpg" style="width:100%;" />
                </div>
                <div class="col-8 mt-2">
                    <h5>I tuoi biglietti sono sempre a portata di mano!</h5>
                    <p>Ora puoi accedere velocemente ai tuoi biglietti entrando nella tua area riservata.
                        <span class="long_version"> Prontoevai ti ricorderà il prossimo viaggio da effettuare e ti mostrerà il biglietto, così potrai rapidamente esibirlo ai controllori.</span></p>
                </div>
            </div>
            <div class="row px-4">
                <div class="col-6 p-0">
                    <button class="btn btn-sm btn-secondary m-1" (click)="openYourTickets();">Vai alla mia area riservata</button>
                </div>
                <div class="col-6 p-0">
                    <button class="btn btn-sm btn-primary m-1" (click)="newTicket();">Acquista un nuovo biglietto</button>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- MODAL -->
<ng-template #modalDayTravel let-modal>
    <div class="modal-header">
        <h4 *ngIf="ticket.id_type==ONLY_START" class="modal-title">Data di partenza</h4>
        <h4 *ngIf="ticket.id_type==START_AND_RETURN" class="modal-title">Date partenza e ritorno</h4>
      </div> 
    <div class="modal-body">
        <div class="text-center" *ngIf="ticket.id_type==ONLY_START">
            <ngb-datepicker #d  [(ngModel)]="date_start_selected" [displayMonths]="1" outsideDays="visible"></ngb-datepicker>
        </div>
        <div class="text-center" *ngIf="ticket.id_type==START_AND_RETURN">
            <ngb-datepicker #d  (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden"></ngb-datepicker>
            <ng-template #t let-date let-focused="focused">
                <span class="custom-day"
                        [class.focused]="focused"
                        [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)"
                        (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                </span>
            </ng-template>
        </div>
     </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary m-auto" (click)="modal.close('cancel')">Ok</button>
    </div>
  </ng-template>



  <ng-template #modalPassword let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Attenzione!</h4>
    </div> 
    <div class="modal-body">
      <p id="modal-text">Il numero di telefono che hai inserito è già presente nei nostri archivi.<br>Inserisci la password che ti è stata inviata alla prima registrazione su ProntoeVai.</p>
        <input type="password" class="form-control" placeholder="la tua password" [(ngModel)]="passwordClient"/>
        
        <div class="mt-2 text-center">
            <button class="btn btn-primary btn-sm" (click)="resendPassword()">Non ricordo la password.<br>Rinvia via SMS</button>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="loginClient();">Prosegui</button>
        <button type="button" class="btn btn-light" (click)="modal.close('cancel')">Annulla</button>
    </div>
  </ng-template>



  <ng-template #modalConditions let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Condizioni di vendita e privacy policy</h4>
    </div> 
    <div class="modal-body">
      <p id="modal-text" [innerHTML]="condition"></p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('cancel')">Ok</button>
    </div>
  </ng-template>


  <ng-template #modalPay let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Paga online</h4>
      </div> 
    <div class="modal-body">
        <div *ngIf="onloadPayment" class="text-center mt-5">
            <img  src="assets/img/search_travel.gif" width="300">
            <h3 class="mt-2">Attendi.<br>
                Stiamo caricando il sistema di pagamento online<br>
            </h3>
        </div>
        <iframe [style.display]="!onloadPayment ? 'block' : 'none'" #paygateway (load)="onLoadIframePay(paygateway)" style="border:none;" width="100%" height="500" [src]="paygatewayurl | safe"></iframe>
    </div>
  </ng-template>


  <ng-template #modalInfoSeat let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Perchè non posso selezionare il numero del posto a sedere?</h4>
      </div> 
    <div class="modal-body">
       I posti a sedere vengono assegnati automanticamente in base alla disponibilità presente sugli autobus. Se hai esigenze particolari puoi sempre contattarci telefonicamente allo <a href="tel:+390983018006" ><b>0983 018006</b></a> e parlare con uno dei nostri operatori, che ti proporranno <b>la miglior soluzione</b> per il tuo viaggio. 
     </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('cancel')">Ok</button>
    </div>
  </ng-template>


  <ng-template #modalWarning let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Attenzione!</h4>
      </div> 
    <div class="modal-body">
       La tua prenotazione è stata confermata ma vi è stato un problema con il server di Simet SPA. Ti invitiamo a contattarci telefonicamente per controllare la corretta emissione del biglietto. Parla con uno dei nostri operatori allo <a href="tel:+390983018006" ><b>0983 018006</b></a>. 
     </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('cancel')">Ok</button>
    </div>
  </ng-template>

