import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { Access } from 'projects/core/src/access';
import { AuthGuard } from 'projects/core/src/authGuard';
import { Config } from 'projects/core/src/config';
import { Globals } from 'projects/core/src/globals';
import { Message } from 'projects/core/src/message';
import { ModalWindow } from 'projects/core/src/modal';
import { NavigationHelper } from 'projects/core/src/navigation';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgxLoadingModule } from 'ngx-loading';
import { C1Connector } from 'projects/core/src/c1connector';
import { SplashScreenComponent } from './views/splash-screen/splash-screen.component';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxLoadingModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
  
  ],
  providers: [AuthGuard,Globals,Config,Access,NavigationHelper,Message,ModalWindow,C1Connector,{ provide: LOCALE_ID, useValue: 'it-IT' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
