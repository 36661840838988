import { Component, OnInit, ViewChild, ElementRef, PipeTransform, Pipe, HostListener} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-2';
import { ToastMode, User } from 'projects/core/src/include/structures';
import { Address } from 'projects/c1-backend/src/app/db/address';
import { Contact } from 'projects/c1-backend/src/app/db/contact';
import { UserService } from 'projects/core/src/lib/user.service';
import { AddressesService } from 'projects/c1-backend/src/app/services/addresses.service';
import { Globals } from 'projects/core/src/globals';
import { PaymentmethodComponent } from '../../modules/paymentmethod/paymentmethod.component';
import { SearchModule } from './search.module';
import { Port } from 'projects/modules/src/app/bustickets/port';
import { TravelDate } from 'projects/modules/src/app/bustickets/travelDate';
import { Travel } from 'projects/modules/src/app/bustickets/travel';
import { Seat } from 'projects/modules/src/app/bustickets/seat';
import { Ticket, TicketDetail } from 'projects/modules/src/app/bustickets/ticket';
import { PortService } from 'projects/modules/src/app/bustickets/services/port.service';
import { TravelService } from 'projects/modules/src/app/bustickets/services/travel.service';
import { TicketsService } from 'projects/modules/src/app/bustickets/services/tickets.service';



export class NgbdDatepickerPopupModule {}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})


  
export class SearchComponent implements OnInit {
/*
  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if(e.data)
      if('task' in e.data)
        if (e.data.task == "successPayment") {
          this.modalService.dismissAll("success");
          this.goTo(this.ORDER_COMPLETE);
          this.ticketsService.getItem(this.ticket.id).subscribe((item)=>{
            this.ticket=item;
            //TODO this.notificationService.sendNotify(item,item.id_address,()=>{

            //});
            //this.notificationService.sendNotificationMessage(item,"tickets",item.id_address,this.contacts);
            //TODO AppComponent.app.getTickets();
        
          });
        }

        if (e.data.task == "errorPayment") {
          this.modalService.dismissAll("success");
          alert("C'è stato un errore nel pagamento");
        }
  }
  */
  
  paygatewayurl:string="";

  @ViewChild("modalDayTravel")
  modalDayTravel:NgbModal;

  @ViewChild("modalPassword")
  modalPassword:NgbModal;
  
  @ViewChild("modalConditions")
  modalConditions:NgbModal;

  @ViewChild("modalInfoSeat")
  modalInfoSeat:NgbModal;
  

  @ViewChild("modalPay")
  modalPay:NgbModal;

  @ViewChild("modalWarning")
  modalWarning:NgbModal;
  
  public STATUS_SEARCH_TRAVEL=1;
  public STATUS_SELECT_RACE_START=2;
  public STATUS_SELECT_RACE_RETURN=3;
  
  public STATUS_SELECT_SEATS=4;
  public STATUS_INSERT_PASSENGERS=99;
  public STATUS_CONFIRM_TICKET=5;
  public STATUS_PAY=6;
  public ORDER_CONFIRMED=7;
  public ORDER_COMPLETE=8;
  public title="";
  


  public ONLY_START=12;
  public START_AND_RETURN=13;

  travelsOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    items:1,
    nav: true,
    navText:[
      "<i class='fa fa-arrow-left mr-2'></i>Giorno precedente",
      "Giorno successivo <i class='fa fa-arrow-right ml-2'></i>"
    ]
  }

  passengersOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    items:1,
    nav: true,
    navText:[
      "<i class='fa fa-arrow-left'></i> passeggero precedente",
      "passeggero successivo <i class='fa fa-arrow-right'></i>"
    ]
  }

  user:User={} as User;
  
  status=this.STATUS_SEARCH_TRAVEL;
  ports_from: Port[]=[];
  ports_to: Port[]=[];
  travelsstart:TravelDate[]=[];
  travelsreturn:TravelDate[]=[];


  date_start_selected:NgbDate;
  date_return_selected:NgbDate | null = null;
  hoveredDate: NgbDate | null = null;

  travel_selected_start:Travel;
  travel_selected_return:Travel;
  
  
  matrix_seat_layout:Seat[][];
  seats_selected:Seat[]=[];

  ticket:Ticket=new Ticket();

  client:Object={} as Object;
  currentClient:Address;
  contacts:Contact[];
  passwordClient:string;
  condition:string;
  onloadPayment=true;

  passengers:Seat[];

  onSaving=false;


  id_type_email=0;
  id_type_telephone=0;

  constructor(
    private userService: UserService,
    private portService: PortService,
    private travelService: TravelService,
    private ticketsService:TicketsService,
    private addressesService:AddressesService,
    private modalService: NgbModal,
    //private notificationService:NotificationService,
    private httpClient:HttpClient,
    private calendar: NgbCalendar
    
    ) { 
      

     this.newTicket();

     this.id_type_email=Globals.parameters.get("busticket")?.getParam("id_email",0);
    this.id_type_telephone=Globals.parameters.get("busticket")?.getParam("id_telephone",0);
      
    }


  newTicket(){
    this.goTo(this.STATUS_SEARCH_TRAVEL);
    this.ticket=new Ticket();
    this.date_start_selected = this.calendar.getToday();
    this.date_return_selected = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
    this.ticket.id_type=this.ONLY_START;
    this.ticket.adults=1;
    this.ticket.children=0;
    this.ticket.cost=0;
  }

  

  ngOnInit(): void {
    
    this.getPortsFrom();
    const requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }

    this.httpClient.get<string>("../assets/condition.html",requestOptions).subscribe((text)=>{
      this.condition=text.toString();
    });
  }

  getPortsFrom():void {
     this.portService.getPorts().subscribe(ports=>{
       this.ports_from=ports;
       this.ticket.port_from=ports[0].stop;
       this.ticket.gps_station_from=ports[0].lat.toString()+","+ports[0].lon.toString();
       this.getPortsTo();
      });
  }

  getPortsTo():void {
    this.portService.getPorts(this.ticket.port_from).subscribe(ports=>{
      this.ports_to=ports;
      this.ticket.port_to=this.ports_to[0].stop;
      this.ticket.gps_station_to=ports[0].lat.toString()+","+ports[0].lon.toString();
    });
 }

 goTo(status):void{
  
  //TODO AppComponent.app.saveAnalytics(String(status));



  switch(status){
    case this.STATUS_SEARCH_TRAVEL:
      this.title="";
      break;

    
    case this.STATUS_SELECT_RACE_START:
      this.title="Seleziona la corsa di andata";

      
      //avvia la ricerca 
      let date_start=new Date(this.date_start_selected.year,this.date_start_selected.month-1,this.date_start_selected.day);
      let date_return=null;
      if(this.ticket.id_type==this.START_AND_RETURN)
        date_return=new Date(this.date_return_selected.year,this.date_return_selected.month-1,this.date_return_selected.day);
      
      
      this.travelsstart=[];
      this.travelsreturn=[];



      this.travelService.getTravel(this.ticket.port_from,this.ticket.port_to,date_start,date_return).subscribe(travels=>{
        
        if(travels.start.length==0){
          alert("Non ci sono corse programmate per la partenza di andata selezionata");
          this.goTo(this.STATUS_SEARCH_TRAVEL);
        }
        for(var i=0;i<travels.start.length;i++){
          travels.start[i]=this.parsingSmartbus(travels.start[i]);
          //calcola lo sconto
          travels.start[i]=this.calculateDiscount(travels.start[i]);
          //calcola il totale 
          travels.start[i].cost=(travels.start[i].PRICES[0].ADULT*(1-travels.start[i].discount/100))*this.ticket.adults+travels.start[i].PRICES[1].CHILDREN*this.ticket.children;

        }

        this.travelsstart=this.groupDate(travels.start); //raggruppa tutte le corse di una stessa data

        if("return" in travels){
          for(var i=0;i<travels.return.length;i++){
            travels.return[i]=this.parsingSmartbus(travels.return[i]);
            //calcola lo sconto
            travels.return[i]=this.calculateDiscount(travels.return[i]);
            travels.return[i].cost=(travels.return[i].PRICES[0].ADULT*(1-travels.return[i].discount/100))*this.ticket.adults+travels.return[i].PRICES[1].CHILDREN*this.ticket.children;

          }
          this.travelsreturn=this.groupDate(travels.return);
        }
        

        
        
        
      });
      break;
    case this.STATUS_SELECT_RACE_RETURN:
      this.title="Seleziona la corsa di ritorno";
      break;
    case this.STATUS_SELECT_SEATS:
        if(this.passengers.length==0){
          for(let i=0;i<this.ticket.adults;i++){
            let s:Seat={} as Seat;
            s.name="";
            s.lastname="";
            s.type="ADULT";
            s.cost=this.travel_selected_start.PRICES[0].ADULT*(1-this.travel_selected_start.discount/100)+(this.ticket.id_type==this.START_AND_RETURN?this.travel_selected_return.PRICES[0].ADULT*(1-this.travel_selected_return.discount/100):0);
            s.id=i+1;
            s.offer=this.travel_selected_start.id_offer;
            this.passengers.push(s);
          }

          for(let i=0;i<this.ticket.children;i++){
            let s:Seat={} as Seat;
            s.name="";
            s.lastname="";
            s.type="CHILDREN";
            s.cost=this.travel_selected_start.PRICES[1].CHILDREN+(this.ticket.id_type==this.START_AND_RETURN?this.travel_selected_return.PRICES[1].CHILDREN:0);
            s.id=i+1;
            s.offer="0";
            this.passengers.push(s);
          }
        }

        this.ticket.cost=this.calculateTotal(this.passengers);


        this.title="Indica i passeggeri";
        break;
    case this.STATUS_INSERT_PASSENGERS:
      this.title="Indica i nominativi dei passeggeri";
      break;
    case this.STATUS_CONFIRM_TICKET:
      this.title="Riepilogo biglietti";
      break;
    case this.STATUS_PAY:

      this.user=Globals.user;

      if(this.user==undefined || typeof(this.user['username'])=="undefined" || (this.user['username']=="") || this.user.id==0){
        //this.user={} as User;
        this.user.name=this.passengers[0].name;
       

      }else{
        if(SearchModule.addressItem){
          this.ticket.id_address=SearchModule.addressItem.id;
          //prendi il numero di telefono
        

          for (let c of SearchModule.addressItem['contacts']){
            if(c['id_type']==this.id_type_telephone){
              this.client['telephone']=c['value'];
            }
            if(c['id_type']==this.id_type_email){
              this.client['email']=c['value'];
            }
          }
          this.client['name']=SearchModule.addressItem['name'];
          
        }
      }
      break;
    case this.ORDER_CONFIRMED:
      //this.openPayMethod();
      break;

  }


  this.status=status;




 }

 calculateDiscount(travel:Travel){
  if(travel!=undefined){
    travel.discount=0;
    travel.id_offer="0";
    travel.discount_description="";
    for(let d of travel.DISCOUNTS){
      //calcola la percentuale di sconto
      if(d.AMOUNT){
        travel.discount=parseFloat(d.AMOUNT.replace("%",""));
        travel.id_offer=d.OFFER;
        travel.discount_description=d.AMOUNT;
      }
    }
  }

  return travel;
 }


 goToBack():void{
   if(this.status==this.STATUS_SELECT_SEATS)
    if(this.ticket.id_type==this.ONLY_START)
      this.status=this.status-1;



   this.goTo(this.status-1);
 }

 goToNext():void{
  this.goTo(this.status+1);
}


 groupDate(travels:Travel[]):TravelDate[]{
  
  var group:TravelDate[]=[];

  for(var i=0;i<travels.length;i++){
    //cerca la data all'interno del raggruppamento
    var idx=-1;

    for(var j=0;j<group.length;j++){
      if(group[j].date==travels[i].TRIP_DATE){
        idx=j;
        continue;
      }
    }


    if(idx>-1){
      //verifica che non ci sia un viaggio con lo stesso SID
      var to_insert=true;
      for(var j=0;j<group[idx].travel.length;j++){
        if(group[idx].travel[j].SID==travels[i].SID){
          to_insert=false;
          continue;
        }
      }
      if(to_insert)
        group[idx].travel.push(travels[i]);
    }else{
      let td={} as TravelDate;
      td.date=travels[i].TRIP_DATE;
      td.travel=[];
      td.travel.push(travels[i]);
      td.id=group.length+1;
      group.push(td);
    }
  }
 

  return group;
 }

 onDateSelection(date: NgbDate) {
  if (!this.date_start_selected && !this.date_return_selected) {
    this.date_start_selected = date;
  } else if (this.date_start_selected && !this.date_return_selected && date.after(this.date_start_selected)) {
    this.date_return_selected = date;
  } else {
    this.date_return_selected = null;
    this.date_start_selected = date;
  }
}

isHovered(date: NgbDate) {
  return this.date_start_selected && !this.date_return_selected && this.hoveredDate && date.after(this.date_start_selected) && date.before(this.hoveredDate);
}


isInside(date: NgbDate) {
  return this.date_return_selected && date.after(this.date_start_selected) && date.before(this.date_return_selected);
}

isRange(date: NgbDate) {
  return date.equals(this.date_start_selected) || (this.date_return_selected && date.equals(this.date_return_selected)) || this.isInside(date) || this.isHovered(date);
}

onSelectTravel(travel:Travel,type="Andata",onGo:boolean=true){
    
  
  this.passengers=[];
  if(type=="Andata"){
    this.travel_selected_start=travel;
  }
  if(type=="Ritorno"){
    this.travel_selected_return=travel;
  }

  if(onGo){
    if(this.ticket.id_type==this.ONLY_START)
      this.goTo(this.STATUS_SELECT_SEATS);
    else
      if(this.status==this.STATUS_SELECT_RACE_START)
        this.goTo(this.STATUS_SELECT_RACE_RETURN);
      else
        this.goTo(this.STATUS_SELECT_SEATS);
  }
    
}


 parsingSmartbus(t:Travel):Travel{
  try{
    t.LIST_SEATS=t.AVAILABLE_SEATS.split(",").map(Number);
  }catch{
    t.LIST_SEATS=[];
    if(t.AVAILABLE_SEATS!="")
      t.LIST_SEATS.push(parseInt(t.AVAILABLE_SEATS));
  }

  t.COUNT_SEATS=t.LIST_SEATS.length;

  try{
    t.LIST_NOT_BUY_SEATS=t.POSTIOCCUPATICURR.split(",").map(Number);
  }catch{
    t.LIST_NOT_BUY_SEATS=[];
    if(t.POSTIOCCUPATICURR!="")
      t.LIST_NOT_BUY_SEATS.push(parseInt(t.POSTIOCCUPATICURR));
  }

  let start:Date=new Date(t.TRIP_DATE+" "+t.START_TIME);
  let stop:Date=new Date(t.TRIP_DATE+" "+t.STOP_TIME);;
  
  t.TRAVEL_TIME=stop.getTime()-start.getTime();

  return t;
 }


 calculateTotal(seats:Seat[]):number{
  let cost:number=0;
   for(var i=0;i<seats.length;i++){
     cost+=seats[i].cost;
   }

   return cost;
  }

 confirmPassengers():void{

  this.ticket.cost=this.calculateTotal(this.passengers);


  for(var i=0;i<this.passengers.length;i++){

    

    if(this.passengers[i].name==""){
      alert("Inserire il nome del "+this.passengers[i].id.toString()+"° passeggero "+(this.passengers[i].type=='ADULT'?"adulto":"bambino"));
      return;
    }

    if(this.passengers[i].lastname==""){
      alert("Inserire il cognome del "+this.passengers[i].id.toString()+"° passeggero "+(this.passengers[i].type=='ADULT'?"adulto":"bambino"));
      
      return;
    }
   }

   this.goTo(this.STATUS_CONFIRM_TICKET);



 }

 registerUser():void{
   /*this.userService.registerUser(this.user).subscribe(user=>{
      console.log(user);
   })*/
 }



 addQuantity(fieldname){
  this.ticket[fieldname]= this.ticket[fieldname]+1;

 }

 reduceQuantity(fieldname,min=0){
   if(this.ticket[fieldname]>min)
   this.ticket[fieldname]=this.ticket[fieldname]-1;
 }



 confirmTicket(){

  if(this.client['telephone']=="" || this.client['telephone']==undefined){
    alert("Inserisci numero di telefono valido");
    return;
  }

  this.ticket.id_state=1;
  this.ticket.created_by=Globals.user?Globals.user.id:0;
  this.ticket.source="webapp";

  this.ticket.tickets=[];


  this.ticket.tickets.push(this.createTicket(this.travel_selected_start));
  if(this.ticket.id_type==this.START_AND_RETURN){
    this.ticket.tickets.push(this.createTicket(this.travel_selected_return));
  }
  
  
  for(var i=0;i<this.passengers.length;i++){
    
    this.passengers[i].telephone=this.client['telephone'];
    this.passengers[i].seat=this.passengers[i].id;

    
  }
  //this.ticket.listpassengers=JSON.stringify(this.seats_selected);

  


  //verifica che i dati del cliente sono stati inseriti
  if(this.ticket.id_address==0 || this.ticket.id_address==undefined){
   

    
    //controlla se il cliente non è registrato (se il numero di telefono non è associato a nessun cliente)
    this.addressesService.searchByContact(this.client['telephone']).subscribe((items)=>{
      if(items.length>0){
        this.currentClient=items[0];
        this.modalService.open(this.modalPassword);
        
        return;
      }else{
        //è un nuovo cliente

        if(this.client['name']=="" || this.client['name']==undefined){
          alert("Inserisci il tuo cognome e nome");
          return;
        }
    
        if(this.client['email']=="" || this.client['email']==undefined){
          if(!confirm("Sicuro di non voler specificare un indirizzo email? Ti ricordiamo che una volta effettuato il pagamento ti invieremo sull'indirizzo email anche una copia PDF del biglietto che hai acquistato!"))
            return;
        }
        

        let c:Address={} as Address;
        c.id=0;
        c.name=this.client['name'];
        c.id_type=1;
        c.contacts=[];
        let t:Contact={} as Contact;
        t.id_type=this.id_type_telephone;
        t.value=this.client['telephone'];
        c.contacts.push(t);
        if(this.client['email']){
          let e:Contact={} as Contact;
          e.id_type=this.id_type_email;
          e.value=this.client['email'];
          c.contacts.push(e);
        }


        this.ticketsService.registerNewUser(c,(user)=>{
          //effettua il login
          
          Globals.access.onLogin(user.username,user.password,"1","");
          
          
          this.ticket.id_address=user.id_address;
          this.ticket.addressItem=c;
          SearchModule.addressItem=this.ticket.addressItem;
          this.contacts=c.contacts;
          this.saveTicket()
        });
      }

      
    });
    
  }else{
    this.saveTicket();
  }

   
   
 }

 saveTicket(){
  this.onSaving=true;
  this.ticketsService.save(this.ticket,(id)=>{
      this.ticket.id=id;

      let ids:number[]=[];
      for(let t of this.ticket.tickets){
        ids.push(t.id);
      }

      //blocca i posti
      this.ticketsService.bookAll(this.ticket.id).subscribe((result:any)=>{
        
        
        
        
        if(result){
          
          //verifica se tutti i biglietti processati sono corretti
          for(let r of result){
            if(r.response=="ERROR"){
              alert("Errore durante il bloccaggio dei posti");
              return;
            }
          }

          this.goTo(this.ORDER_CONFIRMED);
          //this.notificationService.sendNotificationMessage(this.ticket,"tickets",this.ticket.id_address,this.contacts);
        }else{
          alert("Errore durante il bloccaggio dei posti");
        }

        this.onSaving=false;
      });

  },false,false);
  
  
 }

 loginClient(){
    //verifica che la password inserita corrisponde ad un utente
    this.userService.checkPassword(this.currentClient.id_user,this.passwordClient).subscribe((items)=>{
      if(items && items.length>0){


          Globals.access.loginEvent.subscribe((result)=>{

            if(result){
              

              this.ticket.id_address=this.currentClient.id;
              this.ticket.addressItem=this.currentClient;
              this.client['name']=this.currentClient.name;
              
              this.contacts=this.currentClient.contacts;
              this.modalService.dismissAll("success");
              this.confirmTicket();
            }else{
              Globals.message.showToaster("Errore durante  la fase di login",ToastMode.DANGER);
            }
          });

          //effettua il login
          Globals.access.onLogin(items[0].username,items[0].password,"1","",true,false,false,false);
              

          
        }else{
          alert("La password inserita non è corretta");
        }
      
      
    });
 }

 openCondition(){
  this.modalService.open(this.modalConditions);
 }

 openPayMethod(){
  
  this.ticketsService.pay(this.ticket.id).subscribe(result=>{
    if(result.status){
      this.paygatewayurl=result.url;
      this.modalService.open(this.modalPay,{size:'lg'});
      
      
    }
  })
 }

 createTicket(travel:Travel){
  
  let t={} as TicketDetail;
  let ticketpassengers=[];

  for(var i=0;i<this.passengers.length;i++){
    let s:Seat={} as Seat;
    s.name=this.passengers[i].name;
    s.lastname=this.passengers[i].lastname;
    s.type=this.passengers[i].type;
    s.cost=s.type=='ADULT'?travel.PRICES[0].ADULT*(1-travel.discount/100):travel.PRICES[1].CHILDREN;
    s.offer=this.passengers[i].offer;
    s.telephone=this.client['telephone'];
    ticketpassengers.push(s);
    
  }


  //biglietto di andata
  t.date=travel.TRIP_DATE;
  t.port_from=travel.START;
  t.port_to=travel.STOP;
  t.station_from=travel.START_ADDRESS;
  t.station_to=travel.STOP_ADDRESS;
  t.gps_station_from=this.ticket.gps_station_from;
  t.gps_station_to=this.ticket.gps_station_to;
  t.time_from=travel.START_TIME;
  t.time_to=travel.STOP_TIME;
  t.listpassengers=JSON.stringify(ticketpassengers);
  t.sid=travel.SID;
  t.cost=this.calculateTotal(ticketpassengers);
  return t;
 }

 onLoadIframePay(myIframe){
  //verifica in che stadio è il frame
  if(myIframe.src!=""){
    this.onloadPayment=false;
  }

  if(myIframe.src.indexOf("notify.php")>0){
    this.modalService.dismissAll("success");
  }
 }


 openYourTickets(){
   Globals.navigation.onNavigate(["/tickets"]);
 }



 openModalDayTravel(){
  this.modalService.open(this.modalDayTravel);
 }


 openModalInfoSeat(){
  this.modalService.open(this.modalInfoSeat);
 }


 resendPassword(){

  if(this.client['telephone']==""){
    alert("Per richiedere la password devi inserire il tuo numero di telefono");
    return;
  }


   this.userService.resendPassword(this.client['telephone']).subscribe((result)=>{
    alert("A breve riceverai una email ed un SMS con la password del tuo account");
   });
 }


paymentComplete(result){
  if(result){
    this.goTo(this.ORDER_COMPLETE);
    this.ticketsService.getItem(this.ticket.id).subscribe((item)=>{
      this.ticket=item;
      //TODO this.notificationService.sendNotify(item,item.id_address,()=>{

      //});
      //this.notificationService.sendNotificationMessage(item,"tickets",item.id_address,this.contacts);
      //TODO AppComponent.app.getTickets();

    });
  }
}

  @ViewChild(PaymentmethodComponent)
  paymentMethodComponent:PaymentmethodComponent;

  pay(){
    Globals.setLoading(true);
    this.ticketsService.getItem(this.ticket.id).subscribe((item)=>{
      this.ticket=item;
      this.paymentMethodComponent.pay();
    });
  }
  warning(code){
    if(code==99){
      this.modalService.open(this.modalWarning);
    }
  }
}
