import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeModule } from 'projects/core/src/common/stripe/stripe.module';

import { PaymentmethodRoutingModule } from './paymentmethod-routing.module';
import { PaymentmethodComponent } from './paymentmethod.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    PaymentmethodComponent
  ],
  exports:[
    PaymentmethodComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    StripeModule,
    PaymentmethodRoutingModule
  ]
})
export class PaymentmethodModule { }
