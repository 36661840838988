<app-splash-screen *ngIf="!onInit"></app-splash-screen>

<!--Navbar-->
<nav class="navbar navbar-light fixed-top">

    <!-- Navbar brand -->
    <div>
        <img class="py-1" src="assets/img/logo_small.png" height="41" />
    </div>

      <!-- Hamburger -->
      <button class="d-block d-lg-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div class="animated-icon1">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
  
      <!-- Dropdown menu -->
      <ul class=" dropdown-menu bg-primary nav" aria-labelledby="dropdownMenuButton">
        <li class="nav-item"><a class="nav-link active" routerLink="/searchticket" routerLinkActive="router-link-active"  ><img src="assets/img/new_travel_icon.png" width="50" /> Nuovo viaggio</a></li>
        <li class="nav-item"><a class="nav-link "  routerLink="/tickets" routerLinkActive="router-link-active" ><img src="assets/img/your_tickets_icon.png" width="50" /> I tuoi biglietti <sub *ngIf="nextTravel!=undefined"><i class="fas fa-bell blink"></i></sub></a></li>
        <li class="nav-item"><a class="nav-link "  routerLink="/account" routerLinkActive="router-link-active" ><img src="assets/img/your_account_icon.png" width="50" /> <span *ngIf="!addressItem">Il tuo account</span><span *ngIf="addressItem">Ciao {{addressItem.name}}</span></a></li>
        <li class="nav-item"><a class="nav-link "  routerLink="/contacts" routerLinkActive="router-link-active"><img src="assets/img/contacts_icon.png" width="50" />Contatti</a></li>
        <!-- <div id="help_me" class="mt-5 mb-2 text-center fixed-bottom long_version">
          <label class="text-white">Hai bisogno di aiuto?</label><br>
          <p class="text-white my-1 mx-auto" href="tel:+390983018006">
            <img src="assets/img/helpdesk.png" height="45" /><br>
            Chiama o chatta con noi.
          </p>
          
        </div> -->
        <small style="font-weight:lighter;font-size:0.5rem;" class="m-3 fixed-bottom text-left">
          @2024 <b>Sem Viaggi e Turismo</b><br>
          P.IVA 02919180782<br>
          Tutti i diritti riservati<br><br>
          Powered by <a style="color:white" href="https://www.codingictsolution.it/"><img height="15" src="./assets/img/logo_coding_white.png"/></a><br>
          build {{build}}</small>
        
      </ul>
  
    <!-- Bar for desktop view -->
    <div class="d-none d-lg-block">
      <ul class="nav">
        <li class="nav-item"><a class="nav-link active mx-3 text-white" routerLink="/searchticket" routerLinkActive="router-link-active"><img src="assets/img/new_travel_icon.png" width="50" /> Nuovo viaggio</a></li>
        <li class="nav-item"><a class="nav-link mx-3 text-white" routerLink="/tickets" routerLinkActive="router-link-active"><img src="assets/img/your_tickets_icon.png" width="50" /> I tuoi biglietti <sub *ngIf="nextTravel!=undefined"><i class="fas fa-bell blink"></i></sub></a></li>
        <li class="nav-item"><a class="nav-link mx-3 text-white"  routerLink="/account" routerLinkActive="router-link-active"><img src="assets/img/your_account_icon.png" width="50" /><span *ngIf="!addressItem">Il tuo account</span><span *ngIf="addressItem">Ciao {{addressItem.name}}</span></a></li>
        <li class="nav-item"><a class="nav-link mx-3 text-white"  routerLink="/contacts" routerLinkActive="router-link-active"><img src="assets/img/contacts_icon.png" width="50" />Contatti</a></li>
      </ul>	
    </div>
  </nav>
  <!--/.Navbar-->


<div *ngIf="onInit" style="padding-top: 50px" >
  
  <router-outlet></router-outlet>
  <ng-template #mainContent ></ng-template>
  
</div>


<!-- pulsante whatsapp -->
<div class="btn_whatsapp_mobile d-block d-lg-none">
  <a class="text-white" href="https://api.whatsapp.com/send?phone=+393938363509" target="_blank" ><i class="fab fa-whatsapp fa-2x"></i></a>
</div>
<!-- pulsante telefono -->
<a class="btn_phone_mobile d-block d-lg-none" href="tel:+390983018006" ><i class="fa fa-phone-alt"></i></a>

<!-- Modal biglietto in corso -->
<ng-template #modalNextTravel let-modal >
  
  <div class="modal-body" class="bg-primary text-white p-4" style="border: 3px solid #fff;">
      <h3 class="text-center">Ciao {{addressItem.name}}</h3>
      <p class="text-center m-0">ti ricordiamo che il tuo prossimo viaggio
        <br>
        <b>{{nextTravel.port_from | uppercase}}
          <span class="long_version"><br><img  src="assets/img/bus-vert_white.png" height="80"><br></span>
          
          {{nextTravel.port_to | uppercase}} </b>
          <br>
        è programmato per giorno</p>
      <h5 class="text-center">{{nextTravel.date | date:'EEEE dd LLLL yyyy'}}</h5>
      <p class="text-center m-0">Ricordati di recarti alla fermata dell'autobus in <br>
  
        <a target="_blank" href="https://www.google.it/maps/place/{{nextTravel.station_from}}, {{nextTravel.port_from}}" class="text-white"><i class="fas fa-map-marker-alt mr-2"></i><b>{{nextTravel.station_from}}</b></a>
          <br>
        almeno 10 minuti prima della partenza programmata per le ore </p>
      <h4 class="text-center">{{nextTravel.time_from}}</h4>
      <br/>
      <div class="text-center">
      <button class="btn btn-secondary heartbeat" (click)="openTicketNextTravel()">Mostra il tuo biglietto all'autista</button>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="closeModalNextTravel()"><i class="fa fa-times mr-2"></i>Chiudi</button>
  </div>
</ng-template>

<div class="footer_copyright text-right d-none d-lg-block pr-1">
  @2024 <b>Sem Viaggi e Turismo</b> - P.IVA 02919180782 - Tutti i diritti riservati | 
  Powered by <a style="color:white" href="https://www.codingictsolution.it/"><img height="15" src="./assets/img/logo_coding_white.png"/></a> - build {{build}}&ensp;</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>